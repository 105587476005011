import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from "../components/HeroOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Title from "../components/Title"
import SubTitle from "../components/SubTitle"
import Descriptions from "../components/Descriptions"
import AboveFooter from "../components/AboveFooter"

export const DataProtectionPageTemplate = ({
  seo,
  hero,
  contactus,
  aboutsection,
  pcisection,
  soxsection,
  frsection,
  sbsection,
  fixedimagesection
}) => (
    <div>
      <SEO title={seo.title} description={seo.description} article_section={seo.article_section} blogservice={true} />
      <HeroOther data={hero} />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <SubTitle title={contactus.title} />
              <SubTitle title={contactus.secondtitle} />
            </div>
            <div className="column is-4">
              <Link to={contactus.buttonlink} className="button" style={{ fontSize: 20, width: "100%", background: "#53c5d1" }}>{contactus.buttontxt}</Link>
              <a href={contactus.buttonlink} >
                <button style={{ fontSize: 20, width: "100%", background: "#53c5d1" }}>{contactus.buttontxt}</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${aboutsection.sectionbg}` }}>
        <div className="container">
          <div style={{ marginTop: "2rem" }}>
            <Title title={aboutsection.title} color={aboutsection.textcolor} />
            <Title title={aboutsection.secondtitle} color={aboutsection.textcolor} />
            <SubTitle title={aboutsection.subheader} color={aboutsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={aboutsection.descriptions} color={aboutsection.textcolor} margin="1rem 0" />
          </div>
          <div className="columns" style={{ height: 80 }}>
            <div className="column is-6"></div>
            <div className="column is-6 mobile-center" >
              <div style={{ maxWidth: 300 }}>
                <PreviewCompatibleImage imageInfo={aboutsection.image} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${pcisection.sectionbg}` }}>
        <div className="container">
          <div style={{ margin: "7rem 0 3rem" }}>
            <Title title={pcisection.title} color={pcisection.textcolor} />
            <Title title={pcisection.secondtitle} color={pcisection.textcolor} />
            <SubTitle title={pcisection.subheader} color={pcisection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={pcisection.descriptions} color={pcisection.textcolor} margin="1rem 0" />
          </div>
        </div>
      </section>
      <section className="section" style={{ margin: "4rem 0" }}>
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <Title title={soxsection.title} />
              <Title title={soxsection.secondtitle} />
              <SubTitle title={soxsection.subheader} margin="1.5rem 0" />
              <Descriptions descriptions={soxsection.descriptions} margin="1rem 0" />
              <div>
                {
                  soxsection.requires.map((require, i) => {
                    return (
                      <p key={i}
                        style={{ color: `${soxsection.textcolor}` }}
                      >
                        - {require}
                      </p>
                    )

                  })
                }
              </div>
            </div>
            <div className="column is-4">
              <div style={{ maxWidth: 300 }}>
                <PreviewCompatibleImage imageInfo={soxsection.image} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${frsection.sectionbg}` }}>
        <div className="container" style={{ padding: "4rem 0" }}>
          <div className="columns" >
            <div className="column is-4">
              <div style={{ maxWidth: 300, margin: "auto" }}>
                <PreviewCompatibleImage imageInfo={frsection.image} />
              </div>
            </div>
            <div className="column is-8">
              <Title title={frsection.title} color={frsection.textcolor} />
              <Title title={frsection.secondtitle} color={frsection.textcolor} />
              <SubTitle title={frsection.subheader} margin="1.5rem 0" color={frsection.textcolor} />
              <Descriptions descriptions={frsection.sidedescriptions} color={frsection.textcolor} />
            </div>
          </div>
          <Descriptions descriptions={frsection.descriptions} color={frsection.textcolor} />
        </div>
      </section>
      <section className="section" style={{ margin: "3rem 0" }}>
        <div className="container">
          <div>
            <Title title={sbsection.title} />
            <Title title={sbsection.secondtitle} />
            <SubTitle title={sbsection.subheader} margin="1.5rem 0" />
            <Descriptions descriptions={sbsection.descriptions} margin="1rem 0" />
          </div>
          <div className="columns">
            <div className="column is-4"></div>
            <div className="column is-4">
              <a href={sbsection.buttonlink}>
                <button style={{ background: `${sbsection.buttoncolor}`, width: "100%", marginTop: "3rem" }}>
                  {sbsection.buttontxt}
                </button>
              </a>
            </div>
            <div className="column is-4"></div>
          </div>
        </div>
      </section>
      <AboveFooter data={fixedimagesection} height="300px" />
    </div>
  )

DataProtectionPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  aboutsection: PropTypes.object,
  pcisection: PropTypes.object,
  soxsection: PropTypes.object,
  frsection: PropTypes.object,
  sbsection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const DataProtectionPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <DataProtectionPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        aboutsection={frontmatter.aboutsection}
        pcisection={frontmatter.pcisection}
        soxsection={frontmatter.soxsection}
        frsection={frontmatter.frsection}
        sbsection={frontmatter.sbsection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

DataProtectionPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default DataProtectionPage

export const pageQuery = graphql`
  query DataProtectionPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "data-protection" } }) {
      frontmatter {
        seo {
          title
          description
          article_section
        }
        hero {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
          title
          secondtitle
          buttontxt
          buttonlink
        }
        aboutsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
          image {
            childImageSharp {
              fluid(maxWidth: 260, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        pcisection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
        }
        soxsection {
          title
          secondtitle
          subheader
          descriptions
          requires
          image {
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        frsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          sidedescriptions
          descriptions
          image {
            childImageSharp {
              fluid(maxWidth: 460, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        sbsection {
          title
          secondtitle
          subheader
          descriptions
          buttontxt
          buttonlink
          buttoncolor
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
